import "./Account.scss";
import { useEffect, useState } from "react";
import { Profile, User } from "../../types";
import { getUser, logout } from "../../api";
import Setting from "../Setting";
import { useProfileContext } from "../../contexts/ProfileContext";
import { useNavigate } from "react-router-dom";

export default function Account() {
  const navigate = useNavigate();
  const { profile, setProfile } = useProfileContext();
  const [user, setUser] = useState({ } as User);

  useEffect(() => {
    getUser().then((result) => {
      if (result.status === 401) {
        navigate('/login');
      } else if (result.status === 200) {
        setUser(result.data);
      }
    });
  }, [profile]);

  async function logoutUser() {
    const token = localStorage.getItem("ss");
    if (token) {
      await logout(token);
      localStorage.removeItem("ss");
    }
    setProfile({} as Profile);
    navigate('/');
  }

  function bondsmanSettings() {
    return (
      <>
        <Setting title="Handle" path="/account/handle">
          { profile.handle ? profile.handle : <span className="info">Required</span> }
        </Setting>
        <Setting title="Tokens" path="/account/tokens">{user.tokens}</Setting>
        <Setting title="Credit Card" path="/account/payment">
          { user.cardNumber ? <span>Ending in {user.cardNumber}</span> : <span className="info">Setup</span> }
        </Setting>
      </>
    );
  }

  function hunterSettings() {
    return (
      <>
        <h2>Public Profile</h2>
        <Setting title="Handle" path="/account/handle">
          { profile.handle ? profile.handle : <span className="info">Required</span> }
        </Setting>
        <Setting title="Stage Name" path="/account/stagename">{ profile.stagename }</Setting>
        <Setting title="Biography" path="/account/bio" />
        <Setting title="Picture" path="/account/picture" />
        <h2>Tax Information</h2>
        <Setting title="Legal Name" path="/account/name">
          { user.firstName ? user.firstName + " " + user.lastName : <span className="info">Required</span> }
        </Setting>
        <Setting title="Address" path="/account/address">
          { user.taxAddress ? user.taxAddress : <span className="info">Required</span> }
        </Setting>
        <Setting title="Identity Verification" path={profile.hunterVerified ? undefined : "/account/identity"}>
          { (profile.hunterVerified) ? <span className="success">Verified</span> : ((user.idFront && user.idSelfie) ? <span className="info">Pending Review</span> : <span className="info">Required</span>) }
        </Setting>
        <Setting title="SSN" path="/account/ssn">
          { user.socialSecurityNumber ? <span>xxx-xx-{user.socialSecurityNumber}</span> : <span className="info">Required</span> }
        </Setting>
        <h2>Banking Information</h2>
        <Setting title="Direct Deposit" path="/account/bank">
          { user.bankAccountNumber ? <span>Ending in {user.bankAccountNumber}</span> : <span className="info">Required</span> }
        </Setting>
      </>
    );
  }

  return (
    <div className="account">
      <h1>Settings</h1>
      <h2>My Account</h2>
      <Setting title="Email" path="/account/email">{user.emailNew || user.email}</Setting>
      { ((user.email && user.emailVerified === false) || user.emailNew) && <Setting title="Verify Email" path="/account/verify"><span className="info">Required</span></Setting> }
      <Setting title="Password" path="/account/password" />
      { (profile.role & 2) !== 0 ? hunterSettings() : bondsmanSettings() }
      <h2>Account Actions</h2>
      <Setting title="Logout" onClick={logoutUser} />
    </div>
  );
}
