import './Membership.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface MembershipProps {
  next?: string;
}

export default function Membership({ next }: MembershipProps) {
  const navigate = useNavigate();

  function nextClicked() {
    navigate(next || '/account');
  }

  return (
    <div className='form-page'>
      <h1>Join the Bounty Elite: Unlock Exclusive Membership Perks</h1>
      <h2>$4.99 per month</h2>
      <ul className="checklist">
        <li>Unlock the fun: Receive 200 tokens monthly to boost your favorite bounties and access exclusive content!</li>
        <li>Manifest your fantasies: Create personalized bounties and see your wildest dreams brought to life by talented creators!</li>
        <li>Immerse yourself in quality: Dive into a world of stunning 4K HDR visuals and high-resolution content, exclusive to members!</li>
      </ul>
      <Button 
        variant="contained" 
        onClick={nextClicked}>
        Next
      </Button>
    </div>
  );
};
