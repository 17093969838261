import { useState } from 'react';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { CreditCardInfo } from '../../types';
import { updateCreditCard } from '../../api';
import CreditCardForm from './CreditCardForm';

export default function SignUpPayment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');
  const [cardInfo, setCardInfo] = useState({} as CreditCardInfo);

  async function applyClicked() {
    try {
      setPageError('');
      const cardResult = await updateCreditCard(cardInfo);
      if (cardResult.status !== 204) {
        setPageError("We encountered an issue while updating your credit card information. Error: " + cardResult.status);
      } else {
        navigate('/');
      }
      setLoading(true);
    }
    catch (error) {
      setPageError("A system error occurred while updating your credit card information. Please try again later, and if the issue persists, contact our support team for assistance. Error: " + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='form-page'>
      <h1>Your almost done! Set up your credit or debit card</h1>
      <CreditCardForm onCardChanged={setCardInfo} />
      <p>By selecting the "Start Membership" button below, you acknowledge and agree to the <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>Terms of Use and Privacy Statement</a>. You also confirm that you are at least 18 years old. Please be aware that your Bounty membership will automatically renew, and you authorize us to charge the membership fee of $4.99 per month to your designated payment method. You may cancel your membership at any time to prevent future charges by navigating to your Account settings and selecting "Cancel Membership."</p>
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Start Membership'}</span>
      </LoadingButton>
    </div>
  );
};
