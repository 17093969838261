import './ProfilePicture.scss';
import { useEffect, useState } from 'react';
import { Alert, LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import MediaUploader from '../MediaUploader';
import { updateProfilePicture } from '../../api';
import { useProfileContext } from '../../contexts/ProfileContext';
import { useAppContext } from '../../contexts/AppContext';
import { Button } from '@mui/material';

interface ProfilePictureProps {
  next?: string;
}

export default function ProfilePicture({ next }: ProfilePictureProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const { setProfile, profile } = useProfileContext();
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState('');
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    setPicture(profile.picture ?? '');
  },[profile]);

  async function onHandleUpload(status: number, file: string) {
    if (status !== 200) {
      setPageError('Error uploading identification front')
    } else {
      setPicture(file);
    }
  }

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!picture) {
        setPageError("Please upload a profile picture to proceed.");
      } else {
        const result = await updateProfilePicture(picture);
        if (result.status !== 200) {
          setPageError("We encountered an issue while updating your identity information. Error Code: " + result.status + ". Please try again or contact support if the issue persists.");
        } else {
          setProfile(result.data);
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Woot! Your profile picture has been successfully updated.");
            navigate('/account');
          }
        }
      }
    }
    catch (error) {
      setPageError("An unexpected error occurred. Please try again or contact our support team if the problem continues. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }  

  return (
    <div className='form-page'>
      <h1>Set Your Profile Picture</h1>
      <h2>Showcase Your Personality</h2>
      <div className='profile-edit-picture' style={{backgroundImage: 'url(https://videos.bounty.fan/' + (picture || 'profile.png') + ')'}} />
      <MediaUploader onUpload={onHandleUpload}>Upload Photo</MediaUploader>
      <p>Choose a profile picture that best represents you. This image will be displayed publicly on your profile and helps your audience connect with you. Pick a photo that is clear, professional, and truly captures your essence as a content creator.</p>
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Submit Profile Picture'}</span>
      </LoadingButton>
      {next && <Button onClick={() => navigate(next)}>Skip for Now</Button>}
    </div>
  );
};
