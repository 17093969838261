import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#d83e86', // Orange shade for primary color
    },
    secondary: {
      main: '#ffc947', // A lighter orange for the secondary color
    },
    background: {
      default: '#303030', // Dark grey background
      paper: '#424242', // Slightly lighter grey for paper elements
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
});

export default theme;
