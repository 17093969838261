import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import PageFooter from "../PageFooter";
import { useProfileContext } from "../../contexts/ProfileContext";
import "./MainLayout.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import SearchDialog from "../SearchDialog";
import NavBar from "../NavBar";
import { Button } from "@mui/material";

export default function MainLayout() {
  const location = useLocation();
  const { profile } = useProfileContext();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    setSearchOpen(false);
  },[location]);

  useEffect(() => {
    if (profile.unresolved) {
      setUsername("");
    } else if (profile.handle) {
      setUsername("Hello, " + profile.handle);
    } else {
      setUsername("Hello, sign in");
    }
  }, [profile]);

  function searchClicked() {
    setSearchOpen(true);
  }

  async function userClicked() {
    navigate(profile._id ? "/account" : "/login");
  }

  return (
    <div className="main-layout">
      <div className="main-layout-inner">
        <div className="main-bar">
          <Button variant="contained" onClick={searchClicked}>Search</Button>
          <Link className="logo" to="/">
            Bounty
          </Link>
          <div className="user" onClick={userClicked}>
            {username}
            <FontAwesomeIcon icon={faUserCircle} />
          </div>
        </div>
        <NavBar />
        <main>
          <Outlet />
        </main>
        <PageFooter />
      </div>
      { searchOpen && <SearchDialog open={searchOpen} setOpen={setSearchOpen} /> }
    </div>
  );
}
