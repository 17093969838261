import './BountyFeed.scss';
import { useEffect, useState } from "react";
import { Bounty } from "../../types";
import { getBounties } from "../../api";
import BountyBar from "./BountyBar";
import { useAppContext } from "../../contexts/AppContext";
import BountyGrid from './BountyGrid';

interface BountyFeedProps {
  search: string;
}

export default function BountyFeed({ search }: BountyFeedProps) {
  const { snackError } = useAppContext();
  const [bounties, setBounties] = useState<Bounty[]>([]);

  useEffect(() => {
    getBounties(search).then(result => {
      if (result.status !== 200) {
        snackError("Error loading bounties: " + result.status);
      } else {
        setBounties(result.data);
      }
    });
  }, [search]);

  return (
    <div className="bounty-feed">
      {bounties.map(bounty => (
        <div className='feed-item' key={bounty._id}>
          <BountyBar bounty={bounty} />
          <BountyGrid bounty={bounty} />
        </div>
      ))}
    </div>
  );
}
