import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { getUser, updateAddress } from '../../api';
import { useAppContext } from '../../contexts/AppContext';

interface AddressEditProps {
  next?: string;
}

export default function AddressEdit({ next }: AddressEditProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const [resolved, setResolved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxAddress, setTaxAddress] = useState('');
  const [taxCity, setTaxCity] = useState('');
  const [taxState, setTaxState] = useState('');
  const [taxZip, setTaxZip] = useState('');
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    getUser().then((result) => {
      setResolved(true);
      if (result.status === 200) {
        setTaxAddress(result.data.taxAddress);
        setTaxCity(result.data.taxCity);
        setTaxState(result.data.taxState);
        setTaxZip(result.data.taxZip);
      }
    });
  }, []);

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!taxAddress.trim()) {
        setPageError("Address is required. Please enter your street address.");
      } else if (!taxCity.trim()) {
        setPageError("City is required. Please enter the name of your city.");
      } else if (!taxState.trim()) {
        setPageError("State is required. Please enter the name of your state or province.");
      } else if (!taxZip.trim()) {
        setPageError("Postal/Zip code is required. Please enter your area's postal or zip code.");
      } else {
        const result = await updateAddress({ taxAddress, taxCity, taxState, taxZip });
        if (result.status !== 204) {
          setPageError("There was an issue updating your address information (Error: " + result.status + "). Please ensure all details are accurate and retry, or contact our support team for help.");
        } else {
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Your address has been successfully updated for tax documentation purposes. Thank you for keeping your information current.");
            navigate('/account');
          }
        }
      }
    }
    catch (error) {
      setPageError("An unexpected error occurred. Please try submitting again later. If the problem continues, please reach out to our support team. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }

  if (!resolved) {
    return <div className='form-page' />;
  }
  
  return (
  <div className='form-page'>
    <h1>Address Verification for Tax Compliance</h1>
    <h2>Enter Your Address Details</h2>
    <p>
      In order to comply with tax regulations, we require your complete and current residential address. This information is necessary for official tax documentation and will be used solely for this purpose.
    </p>
    <TextField
      label="Street Address"
      variant="outlined"
      value={taxAddress}
      helperText="Enter the street address of your residence."
      onChange={event => {
        setTaxAddress(event.target.value.substring(0, 50));
      }}
    />
    <TextField
      label="City"
      variant="outlined"
      value={taxCity}
      helperText="Enter the city of your residence."
      onChange={event => {
        setTaxCity(event.target.value.substring(0, 50));
      }}
    />
    <TextField
      label="State"
      variant="outlined"
      value={taxState}
      helperText="Enter the state or province of your residence."
      onChange={event => {
        setTaxState(event.target.value.substring(0, 20));
      }}
    />
    <TextField
      label="Postal / Zip Code"
      variant="outlined"
      value={taxZip}
      helperText="Enter your area's postal or zip code."
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          applyClicked();
        }
      }}
      onChange={event => {
        setTaxZip(event.target.value.substring(0, 20));
      }}
    />
    {pageError && <Alert severity="error">{pageError}</Alert> }
    <LoadingButton 
      variant="contained" 
      loading={loading} 
      onClick={applyClicked}>
      <span>{"Submit Address Details"}</span>
    </LoadingButton>
  </div>
  );
};
