import './RankedClaims.scss';
import { Claim } from '../../types';
import ClaimThumb from './ClaimThumb';

interface RankedClaimsProps {
  claims: Claim[];
}

export default function RankedClaims({ claims }: RankedClaimsProps) {

  return (
    <div className="ranked-claims">
      {
        claims.map((claim) => 
          <ClaimThumb claim={claim} />
        )
      }
    </div>
  );
}
