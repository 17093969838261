import './NavBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import useIsMobile from '../hooks/useIsMobile';

// import { faCoins } from '@fortawesome/free-solid-svg-icons';
// import { faCamera } from '@fortawesome/free-solid-svg-icons';
// import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

export default function NavBar() {
  const isMobile = useIsMobile();

  function linkClicked() {
    window.scrollTo(0, 0);
  }

  return (
    <div className='nav-bar'>
      <div className='nav-bar-links'>
        <NavLink className={({ isActive }) => (isActive ? 'nav-bar-link nav-bar-active' : 'nav-bar-link')} to='/' onClick={linkClicked}>
          <FontAwesomeIcon icon={faHome} />
          { !isMobile && <span>Home</span> }
        </NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'nav-bar-link nav-bar-active' : 'nav-bar-link')} to='/profile' onClick={linkClicked}>
          <FontAwesomeIcon icon={faUser} />
          { !isMobile && <span>Profile</span> }
        </NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'nav-bar-link nav-bar-active' : 'nav-bar-link')} to='/bounty/create' onClick={linkClicked}>
          <FontAwesomeIcon icon={faAdd} />
          { !isMobile && <span>Post</span> }
        </NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'nav-bar-link nav-bar-active' : 'nav-bar-link')} to='/creators' onClick={linkClicked}>
          <FontAwesomeIcon icon={faUsers} />
          { !isMobile && <span>Hunters</span> }
        </NavLink>
        <NavLink className={({ isActive }) => (isActive ? 'nav-bar-link nav-bar-active' : 'nav-bar-link')} to='/message' onClick={linkClicked}>
          <FontAwesomeIcon icon={faMessage} />
          { !isMobile && <span>Messages</span> }
        </NavLink>
      </div>
    </div>
  );
};
