import { useEffect, useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useProfileContext } from '../../contexts/ProfileContext';
import { updateBio } from '../../api';
import { useAppContext } from '../../contexts/AppContext';

interface BioEditProps {
  next?: string;
}

export default function BioEdit({ next }: BioEditProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const { setProfile, profile } = useProfileContext();
  const [pageError, setPageError] = useState('');
  const [loading, setLoading] = useState(false);
  const [bio, setBio] = useState('');

  useEffect(() => {
    setBio(profile.bio ?? '');
  },[profile]);

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);

      const result = await updateBio(bio || 'null');
      if (result.status !== 200) {
        setPageError('We encountered a problem while setting your bio (Error: ' + result.status + '). Please try again, or contact support if the issue persists.');
      } else {
        setProfile(result.data);
        if (next) {
          navigate(next);
        } else {
          snackSuccess("Congrats! Your profile biography has been successfully updated.");
          navigate('/account');
        }
      }
    }
    catch (error) {
      setPageError('A system error occurred. Please try again later, or if the problem continues, reach out to our support team. Error Details: ' + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='form-page'>
      <h1>Create Your Biography</h1>
      <h2>Share Your Story</h2>
      <p>Your biography is your chance to connect with your audience. Use this space to share a bit about yourself, your passions, and what inspires your content. Highlight your unique talents and what makes your offerings special. This is your opportunity to engage and intrigue potential subscribers, so let your personality shine through!</p>
      <TextField
        helperText='Max 200 characters. Make every word count!'
        multiline
        rows={4}
        variant='outlined'
        value={bio}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            applyClicked();
          }
        }}
        onChange={(event) => {
          setBio(event.target.value.substring(0,200));
        }}
      />
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Update Biography'}</span>
      </LoadingButton>
      {next && <Button onClick={() => navigate(next)}>Skip for Now</Button>}
    </div>
  );
};
