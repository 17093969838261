import axios, { AxiosResponse } from 'axios';
import { Session, Bounty, Claim, Profile, User, Vote, Post, CreditCardInfo, BankInfo, AddressInfo, BountyInfo, Tag } from './types';

interface ApiResponse<T> {
  status: number;
  data: T;
}

async function handleApiRequest<T>(request: Promise<AxiosResponse<T>>): Promise<ApiResponse<T>> {
  try {
    const response = await request;
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error: ", error.message);
      return {
        status: error.response?.status || 500,
        data: {} as T,
      };
    } else {
      console.error("Unexpected error: ", error);
      return {
        status: 500,
        data: {} as T,
      };
    }
  }
}

const api = axios.create({
  baseURL: 'https://seahorse-app-6765g.ondigitalocean.app',
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('ss');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function getUser(): Promise<ApiResponse<User>> {
  const request = api.get<User>('/user');
  return await handleApiRequest(request);
}

export async function createUser(email: string, password: string, hunter: boolean): Promise<ApiResponse<Profile>> {
  const request = api.post<Profile>('/user', { email, password, hunter });
  return await handleApiRequest(request);
}

export async function updateCreditCard(cardInfo: CreditCardInfo): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/card', cardInfo);
  return await handleApiRequest(request);
}

export async function updateName(firstName: string, lastName: string): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/name', { firstName, lastName });
  return await handleApiRequest(request);
}

export async function updateAddress(address: AddressInfo): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/address', address);
  return await handleApiRequest(request);
}

export async function updateIdentity(idFront: string, idSelfie: string): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/identity', { idFront, idSelfie });
  return await handleApiRequest(request);
}

export async function updateBankInfo(bankInfo: BankInfo): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/bank', bankInfo);
  return await handleApiRequest(request);
}

export async function updateSocialSecurityNumber(socialSecurityNumber: string): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/ssn/' + socialSecurityNumber);
  return await handleApiRequest(request);
}

export async function updateEmail(email: string): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/user/email/' + email);
  return await handleApiRequest(request);
}

export async function sendVerifyEmail(): Promise<ApiResponse<void>> {
  const request = api.post<void>('/email/verify');
  return await handleApiRequest(request);
}

export async function verifyEmail(code: string): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/user/email/verify/' + code);
  return await handleApiRequest(request);
}

export async function sendReset(email: string): Promise<ApiResponse<void>> {
  const request = api.post<void>('/email/password/' + email);
  return await handleApiRequest(request);
}

export async function resetPassword(resetCode: string, newPassword: string): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/reset', { resetCode, newPassword });
  return await handleApiRequest(request);
}

export async function updatePassword(oldPassword: string, newPassword: string): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/password', { oldPassword, newPassword });
  return await handleApiRequest(request);
}

export async function getProfile(): Promise<ApiResponse<Profile>> {
  const request = api.get<Profile>('/profile');
  return await handleApiRequest(request);
}

export async function getProfileById(id: string): Promise<ApiResponse<Profile>> {
  const request = api.get<Profile>('/profile/' + id);
  return await handleApiRequest(request);
}

export async function getProfileByHandle(handle: string): Promise<ApiResponse<Profile>> {
  const request = api.get<Profile>('/profile/handle/' + handle);
  return await handleApiRequest(request);
}

export async function updateProfile(profile: Profile): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/profile', profile);
  return await handleApiRequest(request);
}

export async function updateHandle(handle: string): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/profile/handle/' + handle);
  return await handleApiRequest(request);
}

export async function updateBio(bio: string): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/profile/bio/' + bio);
  return await handleApiRequest(request);
}

export async function updateStageName(stagename: string): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/profile/stagename/' + stagename);
  return await handleApiRequest(request);
}

export async function updateProfilePicture(picture: string): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/profile/picture/' + picture);
  return await handleApiRequest(request);
}

export async function updateBanner(banner: string): Promise<ApiResponse<Profile>> {
  const request = api.put<Profile>('/profile/banner/' + banner);
  return await handleApiRequest(request);
}

export async function authenticate(email: string, password: string): Promise<ApiResponse<Session>> {
  const request = api.post<Session>('/auth', { email, password });
  return await handleApiRequest(request);
}

export async function logout(sessionId: string): Promise<ApiResponse<Session>> {
  const request = api.delete<Session>('/auth/' + sessionId);
  return await handleApiRequest(request);
}

export async function postBounty(bountyInfo: BountyInfo): Promise<ApiResponse<Bounty>> {
  const request = api.post<Bounty>('/bounty', bountyInfo);
  return await handleApiRequest(request);
}

export async function fundBounty(bountyId: string, tokens: number): Promise<ApiResponse<null>> {
  const request = api.post<null>('/bounty/fund', { bountyId, tokens });
  return await handleApiRequest(request);
}

export async function getBounties(term: string): Promise<ApiResponse<Bounty[]>> {
  const request = api.get<Bounty[]>('/bounties/', { params: {
    term: encodeURIComponent(term)
  }});
  return await handleApiRequest(request);
}

export async function getBounty(bountyId: string): Promise<ApiResponse<Bounty>> {
  const request = api.get<Bounty>('/bounty/' + bountyId);
  return await handleApiRequest(request);
}

export async function postClaim(bountyId: string, fileType: number, fileName: string, fileExtension: string): Promise<ApiResponse<Claim>> {
  const request = api.post<Claim>('/claim', { bountyId, fileType, fileName, fileExtension });
  return await handleApiRequest(request);
}

export async function getBountyClaims(bountyId: string): Promise<ApiResponse<Claim[]>> {
  const request = api.get<Claim[]>('/bounty/claims/' + bountyId);
  return await handleApiRequest(request);
}

export async function getUserClaims(userId: string): Promise<ApiResponse<Claim[]>> {
  const request = api.get<Claim[]>('/user/claims/' + userId);
  return await handleApiRequest(request);
}

export async function getClaimRank(bountyId: string, claimId: string): Promise<ApiResponse<number>> {
  const request = api.get<number>('/bounty/' + bountyId + '/claim-rank/' + claimId);
  return await handleApiRequest(request);
}

export async function getClaim(claimId: string): Promise<ApiResponse<Claim>> {
  const request = api.get<Claim>('/claim/' + claimId);
  return await handleApiRequest(request);
}

export async function postVote(claimId: string): Promise<ApiResponse<Vote>> {
  const request = api.post<Vote>('/vote', { claimId });
  return await handleApiRequest(request);
}

export async function getVote(bountyId: string): Promise<ApiResponse<Vote>> {
  const request = api.get<Vote>('/vote/' + bountyId);
  return await handleApiRequest(request);
}

export async function postPost(text: string, fileName: string): Promise<ApiResponse<Post>> {
  const request = api.post<Post>('/post', { text, fileName });
  return await handleApiRequest(request);
}

export async function getPosts(userId: string): Promise<ApiResponse<Post[]>> {
  const request = api.get<Post[]>('/posts/' + userId);
  return await handleApiRequest(request);
}

export async function searchTags(search: string): Promise<ApiResponse<Tag[]>> {
  const request = api.get<Tag[]>('/keyword/' + search);
  return await handleApiRequest(request);
}