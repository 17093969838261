import './FormLayout.scss';
import { Link, Outlet } from 'react-router-dom';
import PageFooter from '../PageFooter';

export default function FormLayout() {

  return (
    <div className='form-layout'>
      <div className='form-layout-logo'>
        <Link className='link' to="/">
          Bounty
        </Link>
      </div>
      <div className='form-layout-inner'>
        <main>
          <Outlet />
        </main>
      </div>
      <PageFooter />
    </div>
  );
};
