import './Login.scss';
import { useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { authenticate, getProfile } from '../api';
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate } from 'react-router-dom';
import { useProfileContext } from '../contexts/ProfileContext';
import useAutofillDetector from '../hooks/useAutofillDetector';

export default function Login() {
  const navigate = useNavigate();
  const { setProfile } = useProfileContext();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [email, setEmail] = useState('');
  const [emailAutofilled, setEmailAutofilled] = useAutofillDetector();
  const [password, setPassword] = useState('');
  const [passwordAutofilled, setPasswordAutofilled] = useAutofillDetector();
 
  async function loginClicked() {
    try {
      setLoading(true);
      const authenticateResult = await authenticate(email, password);
      if (authenticateResult.status === 400) {
        setLoginError("Invalid login credentials. Please try again.");
      } else if (authenticateResult.status !== 200) {
        setLoginError("Error authenticate (" + authenticateResult.status + ")");
      } else {
        localStorage.setItem('ss', authenticateResult.data._id);
        const profileResult = await getProfile();
        if (profileResult.status !== 200) {
          setLoginError("Error loading profile (" + profileResult.status + ")");
        }
        else {
          setProfile(profileResult.data);
          navigate('/');
        }
      }
    }
    catch (error) {
      setLoginError("Error " + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='form-page'>
      <h1>Sign In</h1>
      <TextField 
        inputProps={{ onAnimationStart: setEmailAutofilled }}
        InputLabelProps={{ shrink: email.length > 0 || emailAutofilled }}
        label="Email"
        value={email}
        onChange={(event) => {
          setLoginError('');
          setEmail(event.target.value);
        }}
      />
      <TextField 
        inputProps={{ onAnimationStart: setPasswordAutofilled }}
        InputLabelProps={{ shrink: password.length > 0 || passwordAutofilled }}
        type='password'
        label="Password"
        value={password}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            loginClicked();
          }
        }}
        onChange={(event) => {
          setLoginError('');
          setPassword(event.target.value);
        }}
      />
      {loginError && (
        <Alert severity="error">{loginError}</Alert>
      )}
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={loginClicked}>
        <span>Sign In</span>
      </LoadingButton>
      <div className='forgot-password'>
        <Link to='/forgot'>Forgot password?</Link>
      </div>
      <p>New to Bounty? <Link to='/signup/member'>Sign up now</Link></p>
      <p>Earn money making content? <Link to='/signup/creator'>Become a creator</Link></p>
    </div>
  );
}
