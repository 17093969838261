import { useNavigate, useParams } from 'react-router-dom';
import './BountyPage.scss';
import { getBounty, getBountyClaims } from '../../api';
import { Bounty, Claim } from '../../types';
import { useEffect, useState } from 'react';
import MediaUploader from '../MediaUploader';
import BountyBar from './BountyBar';
import RankedClaims from './RankedClaims';

export default function BountyPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [bounty, setBounty] = useState({} as Bounty);
  const [claims, setClaims] = useState([] as Claim[]);

  useEffect(() => {
    if (id) {
      getBounty(id).then(result => {
        setBounty(result.status === 200 ? result.data : {} as Bounty);
      });
      getBountyClaims(id).then(result => {
        setClaims(result.status === 200 ? result.data : []);
      });
    }
  }, [id]);

  /* async function fundClicked() {
    const tokens = Number(fundTokens);
    if (bounty?._id && tokens > 0) {
      const fundResults = await fundBounty(bounty._id, tokens);
      if (fundResults.status !== 200) {
        console.error("fundBounty: " + fundResults.status);
      } else {
        setFundOpen(false);
        setLoadCount(count => count + 1);
      }
    }
  } */

  async function onHandleUpload(status: number, file: string) {
    if (status !== 200) {
      console.error("onHandleUpload", status);
    } else {
      navigate('/claim/create/' + bounty._id + '/' + file);
    }
  }

  return (
    <div className='content-page'>
      <BountyBar bounty={bounty} />
      <MediaUploader onUpload={onHandleUpload}>Add Claim</MediaUploader>
      <RankedClaims claims={claims} />
    </div>
  );
};
