import './ClaimThumb.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Claim } from '../../types';
import { appConfig } from '../../appConfig';

interface ClaimThumbProps {
  claim: Claim;
}

export default function ClaimThumb({ claim }: ClaimThumbProps) {
  if (claim.fileType === 1) return (
    <Link 
      className='claim-thumb-image' 
      to={'/claim/' + claim._id + '?q=cats'} 
      style={{backgroundImage: 'url(' + appConfig.mediaUrl + 't-' + claim.fileName + '.webp)'}}
    >
      <span className="votes">{claim.votes}<FontAwesomeIcon icon={faThumbsUp} /></span>
    </Link>
  );

  return (
    <Link className='claim-thumb-video' to={'/claim/' + claim._id + '?q=cats'}>
      <div className="video-container">
        <video autoPlay muted loop className="video-background">
          <source src={"https://videos.bounty.fan/" + claim.fileName + "_p.mp4" } type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay">
        <span className="votes">{claim.votes}<FontAwesomeIcon icon={faThumbsUp} /></span>
        </div>
      </div>
    </Link>
  );
}
