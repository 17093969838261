import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useProfileContext } from '../../contexts/ProfileContext';
import { updateHandle } from '../../api';
import { useAppContext } from '../../contexts/AppContext';
import PageLoading from '../PageLoading';

interface HandleEditProps {
  next?: string;
}

export default function HandleEdit({ next }: HandleEditProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const { setProfile, profile } = useProfileContext();
  const [resolved, setResolved] = useState(false);
  const [pageError, setPageError] = useState('');
  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState('');

  useEffect(() => {
    setResolved(true);
    setHandle(profile.handle ?? '');
  },[profile]);

  function handleChanged(event: any) {
    let newHandle = event.target.value;
    newHandle = newHandle.replace(/[^a-zA-Z0-9]/g, ''); 
    setHandle(newHandle.substring(0,15));
  }

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!handle.trim()) {
        setPageError("Please enter a user handle before proceeding.");
      } else {
        const result = await updateHandle(handle);
        if (result.status === 409) {
          setPageError('The chosen handle is already in use. Please select a different handle.');
        } else if (result.status !== 200) {
          setPageError('We encountered a problem while setting your handle (Error: ' + result.status + '). Please try again, or contact support if the issue persists.');
        } else {
          setProfile(result.data);
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Hooray! Your profile handle has been successfully updated.");
            navigate('/account');
          }

        }
      }
    }
    catch (error) {
      setPageError('A system error occurred. Please try again later, or if the problem continues, reach out to our support team. Error Details: ' + error);
    }
    finally {
      setLoading(false);
    }
  }

  if (!resolved) {
    return <PageLoading />;
  }

  return (
    <div className='form-page'>
      <h1>Create Your Public User Handle</h1>
      <h2>Stand Out on Bounty</h2>
      <p>Your user handle is your unique identity on Bounty and how others will recognize and interact with you. Choose a handle that reflects your persona or brand. Keep it memorable and distinct to ensure you stand out in the community.</p>
      <TextField
        label='User Handle'
        helperText="Choose a handle between 3-15 characters. Avoid special characters."
        variant='outlined'
        value={handle}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            applyClicked();
          }
        }}
        onChange={handleChanged}
      />
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Set My Handle'}</span>
      </LoadingButton>
    </div>
  );
};
