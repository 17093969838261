import { useLocation, useParams } from 'react-router-dom';
import './ClaimPage.scss';
import { useEffect, useState } from 'react';
import { Claim, Vote } from '../../types';
import { getClaim, getVote, postVote } from '../../api';
import BountyBar from './BountyBar';
import { useAppContext } from '../../contexts/AppContext';
import ClaimBar from './ClaimBar';
import BountyFeed from './BountyFeed';
import HunterBar from './HunterBar';
import VideoPlayer from '../VideoPlayer';
import { Button } from '@mui/material';
import ImageViewer from '../ImageViewer';

export default function ClaimPage() {
  const { id } = useParams();
  const { snackError } = useAppContext();
  const [loadCount, setLoadCount] = useState(0);
  const [claim, setClaim] = useState({ } as Claim);
  const [vote, setVote] = useState({ unresolved: true } as Vote);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get('q') ?? '';

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadCount(value => value + 1);
    console.log('CLAIM: ' + id);
  }, [id]);

  useEffect(() => {
    if (id && loadCount) {
      const storedData = localStorage.getItem(id);
      if (storedData) {
        console.log("GOT: " + storedData);
        setClaim(JSON.parse(storedData));
      }

      getClaim(id).then(result => {
        if (result.status !== 200) {
          snackError("Error loading claim: " + result.status);
          console.error('getClaim: %d', result.status);
        } else {
          localStorage.setItem(id, JSON.stringify(result.data));
          setClaim(result.data);
        }
      });
      getVote(id).then(result => {
        if (result.status !== 200) {
          snackError("Error loading vote: " + result.status);
          console.error('getVote: %d', result.status);
        } else {
          setVote(result.data);
        }
      });
    }
  }, [loadCount]);

  async function voteClicked() {
    setVote({ unresolved: true } as Vote);
    const voteResult = await postVote(claim._id);
    if (voteResult.status !== 200) {
      console.error("postVote: " + voteResult.status);
    } else {
      setLoadCount(value => value + 1);
      setVote(voteResult.data);
    }
  }
  
  return (
    <div className='claim-page'>
      <div className='claim-box'>
        <BountyBar bounty={claim.bounty} />
        {
          claim.fileType === 2 ?
          <VideoPlayer fileName={claim.fileName + '.m3u8'} />
          :
          <ImageViewer fileName={claim.fileName} width={claim.width} height={claim.height} />
        }
        <ClaimBar claim={claim} />
        <div className='claim-actions'>
          <Button 
            variant="contained"
            disabled={!claim._id || vote.unresolved || vote.claimId === id} 
            onClick={voteClicked}>
            Vote
          </Button>
          <Button 
            variant="contained" 
            disabled={vote.unresolved || vote.claimId === id} 
            onClick={voteClicked}>
            Fund
          </Button>
          <Button 
            variant="contained" 
            disabled={vote.unresolved || vote.claimId === id} 
            onClick={voteClicked}>
            Save
          </Button>
        </div>
      </div>
      <HunterBar profile={claim.profile} />
      <BountyFeed search={searchTerm} />
    </div>
  );
}