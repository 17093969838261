import './EmailVerify.scss';
import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useProfileContext } from '../../contexts/ProfileContext';
import { getUser, sendVerifyEmail, verifyEmail } from '../../api';
import { User } from '../../types';
import { useAppContext } from '../../contexts/AppContext';

interface EmailVerifyProps {
  next?: string;
}

export default function EmailVerify({ next }: EmailVerifyProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const { setProfile } = useProfileContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('')
  const [code, setCode] = useState('');
  const [user, setUser] = useState({} as User);

  useEffect(() => {
    getUser().then(userResult => {
      if (userResult.status !== 200) {
        setError('Error retreving user. Code ' + userResult.status);
      } else {
        setUser(userResult.data);
        if (userResult.data.email) {
          sendVerifyEmail().then(sendResult => {
            if (sendResult.status === 204) {
              snackSuccess('Verification email sent, check inbox')
            } else {
              setError("Error sending verification email. Please refresh page.")
            }
          });
        }
      }
    });
  }, []);

  async function applyClicked() {
    try {
      setError('');
      if (code.length !== 6) {
        setError('Invalid Code: Please enter a 6-digit validation code.')
      } else {
        setLoading(true);
        const updateResult = await verifyEmail(code);
        if (updateResult.status === 400) {
          setError('Invalid code: Please check email and try again.');
        } else if (updateResult.status !== 200) {
          setError('Error verifying email ' + updateResult.status);
        } else {
          setProfile(updateResult.data);
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Great news! Your email has been successfully verified.");
            navigate('/account');
          }
        }
      }
    }
    catch (error) {
      setError("Error " + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='form-page'>
      <h1>Verify Email</h1>
      <h2>A verficiation code has been sent to</h2>
      <h2>{user.emailNew || user.email}</h2>
      <TextField
        label='Code'
        helperText='Please check your inbox and enter the verification code below to verify your email address.'
        variant='outlined'
        value={code}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            applyClicked();
          }
        }}
        onChange={(event) => {
          setError('');
          setCode(event.target.value);
        }}
      />
      {error && (
        <Alert severity="error">{error}</Alert>
      )}
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>Verify</span>
      </LoadingButton>
    </div>
  );
};
