import './BountyGrid.scss';
import { Bounty, Claim } from '../../types';
import ClaimThumb from './ClaimThumb';

interface BountyGridProps {
  bounty: Bounty;
}

export default function BountyGrid({ bounty }: BountyGridProps) {

  function getRandomClaims(): Claim[] {
    let result = [...bounty.claims];
    if (result.length < 9) {
      while (result.length % 3 !== 0) {
        result.push({} as Claim);
      }
    }
    return result.sort(() => 0.5 - Math.random()).slice(0, result.length > 9 ? 9 : result.length);
  }

  return (
    <div className="bounty-grid" key={bounty._id}>
    {
      getRandomClaims().map(claim => <ClaimThumb key={claim._id} claim={claim} />)
    }
    </div>
  );
}
