import './CreditCardForm.scss';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { CreditCardInfo } from '../../types';

interface CreditCardFormProps {
  onCardChanged: (info: CreditCardInfo) => void;
}

export default function CreditCardForm({ onCardChanged }: CreditCardFormProps) {
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [cardVerificationValue, setCardVerificationValue] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardZip, setCardZip] = useState('');

  useEffect(() => {
    const split = cardExpiration.split('/');
    const cardInfo = {
      cardNumber: cardNumber.replaceAll(' ', ''),
      cardVerificationValue,
      cardMonth: split[0],
      cardYear: split.length >= 2 ? split[1] : '',
      cardName,
      cardZip,
    } as CreditCardInfo;
    onCardChanged(cardInfo);
  },[cardNumber, cardExpiration, cardVerificationValue, cardName, cardZip]);

  function cardNumberChanged(event: any) {
    let value = event.target.value as string;
    value = value.replace(/[^\s0-9]/g, '');
    setCardNumber(value);
  }

  function cardExpirationChanged(event: any) {
    let value = event.target.value as string;
    value = value.replace(/[^\d]/g, '');
    if (value.length == 1 && value !== '0' && value !='1') {
      value = '0' + value;
    }
    if (value.length > 2 || (value.length == 2 && cardExpiration.length < value.length)) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    setCardExpiration(value.substring(0, 5));
  }

  function cardVerificationValueChanged(event: any) {
    let value = event.target.value as string;
    value = value.replace(/[^\d]/g, '');
    setCardVerificationValue(value.substring(0, 4));
  }

  function cardNameChanged(event: any) {
    let value = event.target.value as string;
    setCardName(value);
  }

  function cardZipChanged(event: any) {
    let value = event.target.value as string;
    setCardZip(value);
  }

  return (
    <div className='credit-card-form'>
      <div className='card-icons'>
        <img src='/visa.png' alt='visa' />
        <img src='/mastercard.png' alt='mastercard' />
        <img src='/americanexpress.png' alt='americanexpress' />
        <img src='/discover.png' alt='discover' />
      </div>
      <TextField
        label='Card number'
        variant='outlined'
        value={cardNumber}
        onChange={cardNumberChanged}
      />
      <div className='experation'>
        <TextField
          label='Expiration date'
          variant='outlined'
          helperText='MM/YY'
          value={cardExpiration}
          onChange={cardExpirationChanged}
        />
        <TextField
          label='CVV'
          variant='outlined'
          helperText='Number on back'
          value={cardVerificationValue}
          onChange={cardVerificationValueChanged}
        />
      </div>
      <TextField
        label='Name on card'
        variant='outlined'
        value={cardName}
        onChange={cardNameChanged}
      />
      <TextField
        label='ZIP Code'
        variant='outlined'
        value={cardZip}
        onChange={cardZipChanged}
      />
    </div>
  );
};
