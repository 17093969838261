import ReactPlayer from 'react-player';
import './VideoPlayer.scss';
import { useEffect, useState } from 'react';


interface VideoPlayerProps {
  fileName: string;
}

export default function VideoPlayer({ fileName }: VideoPlayerProps) {
  const [windowHeight, setWindowHeight] = useState(600);

  useEffect(() => {
    function handleResize() {
      setWindowHeight( window.innerHeight);
    }    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='video-player'>
      <ReactPlayer 
        url={"https://videos.bounty.fan/" + fileName} 
        controls={true}
        width='100%'
        height={windowHeight / 2}
      />
    </div>
  );
}
