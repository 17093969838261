import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { getUser, updateEmail } from '../../api';
import { isValidEmail } from '../../globals';
import { useAppContext } from '../../contexts/AppContext';
import PageLoading from '../PageLoading';

export default function EmailEdit() {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const [resolved, setResolved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    getUser().then((result) => {
      setResolved(true);
      if (result.status === 200) {
        setNewEmail(result.data.email);
      }
    });
  }, []);

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!isValidEmail(newEmail)) {
        setPageError("Please enter a valid email address. Ensure it's correctly formatted.");
      } else {
        const result = await updateEmail(newEmail);
        switch (result.status) {
          case 400:
            setPageError("The provided email address is invalid. Please enter a valid email.");
            break;
          case 406:
            setPageError("This email address is already associated with an account. Please use a different email or log in to your existing account.");
            break;
          case 204:
            snackSuccess("Great news! Your email has been successfully changed.");
            navigate('/account');
            break;
          case 205:
            navigate('/account/verify');
            break;
          default:
            setPageError("Unexpected error occurred (Error: " + result.status + "). Please check your details and try again, or contact support for assistance.");
        }
      }
    }
    catch (error) {
      setPageError("A system error occurred. Please try again later. If the problem persists, reach out to our support team for assistance. Error details: " + error);
    }
    finally {
      setLoading(false);
    }
  }  

  if (!resolved) {
    return <PageLoading />;
  }
  
  return (
  <div className='form-page'>
    <h1>Update Your Email Address</h1>
    <h2>Securely Change Your Contact Email</h2>
    <p>Your email address is essential for account security and communication. Enter the new email address you wish to associate with your account. Post submission, a verification code will be sent to your new email for confirmation.</p>
    <TextField
      label="New Email Address"
      variant="outlined"
      value={newEmail}
      helperText="Please enter a valid email address that you have access to."
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          applyClicked();
        }
      }}
      onChange={event => {
        setNewEmail(event.target.value.substring(0, 320));
      }}
    />
    {pageError && <Alert severity="error">{pageError}</Alert> }
    <LoadingButton 
      variant="contained" 
      loading={loading} 
      onClick={applyClicked}>
      <span>{"Update Email"}</span>
    </LoadingButton>
  </div>
  );
};
