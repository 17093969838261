import './BountyBar.scss';
import { Bounty } from '../../types';
import { Link } from 'react-router-dom';
import { bountyActive, formatCountdown, formatMoney } from '../../globals';
import { useEffect, useState } from 'react';

interface BountyBarProps {
  bounty: Bounty;
}

export default function BountyBar({ bounty }: BountyBarProps) {
  const [countdown, setCountdown] = useState('');
  const [active, setActive] = useState(false);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (!bounty) {
        setActive(false);
        setCountdown('');
      } else {
        setActive(bountyActive(bounty.endDate));
        setCountdown(formatCountdown(bounty.endDate, true));
      }
    }, 5000);
    return () => clearInterval(timerId);
  }, [bounty]); 

  if (!bounty) {
    return <div />;
  }

  return (
    <div className='bounty-bar'>
      <Link className='title' to={'/bounty/' + bounty._id} >{bounty.title}</Link>
      <div className='details'>
        {
          active ? 
          <div className='countdown'>Active: {countdown} remaining</div>
          :
          <div className='countdown'>Finalized: {countdown} ago</div>
        }
        <div className='amount'>Prize Pool: {formatMoney(bounty.tokens)}</div>
      </div>
    </div>
  );
};
