import { useEffect, useState } from 'react';
import { Alert, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getBounty, postClaim } from '../../api';
import { Bounty } from '../../types';
import PageLoading from '../PageLoading';
import { getFileUploadInfo } from '../../globals';
import { appConfig } from '../../appConfig';

export default function ClaimCreate() {
  const { bountyId, file } = useParams();
  const navigate = useNavigate();
  const [pageError, setPageError] = useState('');
  const [bounty, setBounty] = useState({} as Bounty);
  const [resolved, setResolved] = useState(false);

  useEffect(() => {
    setResolved(false);
    if (bountyId) {
      getBounty(bountyId).then(result => {
        setResolved(true);
        if (result.status !== 200) {
          setPageError('Error loading bounty: ' + result.status);
        } else {
          setBounty(result.data);
        }
      });
    }
  }, [bountyId]);

  async function postClicked() {
    try {
      setPageError('');
      if (!file) {
        setPageError('Upload not found');
      } else if (!bounty._id) {
        setPageError('Bounty not found');
      } else {
        const info = getFileUploadInfo(file);
        console.log("FILEINFO: " + JSON.stringify(info));
        const result = await postClaim(bounty._id, info.fileType, info.fileName, info.fileExtension);
        if (result.status !== 200) {
          setPageError('Error posting claim: ' + result.status);
        } else {
          navigate('/claim/' + result.data._id);
        }
      }
    }
    catch (error) {
      console.error("postClicked", error);
      setPageError("Post error: " + error);
    }
  }

  if (!resolved) return <PageLoading />

  console.log('BOUNTY: ' + bountyId + ' FILE: ' + file)

  return (
    <div className='form-page'>
      <h1>Create Claim</h1>
      <h2>{bounty.title}</h2>
      {file && <img className='post-picture' src={appConfig.mediaUrl + file } alt='post' />}
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <Button 
        variant="contained" 
        onClick={postClicked}>
        <span>Post</span>
      </Button>
      <Button onClick={() => { navigate('/bounty/' + bountyId)}}>Cancel</Button>
    </div>
  );
};
