import React, { createContext, useContext } from 'react';
import { Profile } from '../types';

export interface ProfileContextType {
  profile: Profile;
  setProfile: React.Dispatch<React.SetStateAction<Profile>>;
}

export function useProfileContext(): ProfileContextType {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfileContext must be used within a ProfileProvider');
  }

  return context;
}

const ProfileContext = createContext<ProfileContextType | null>(null);
export default ProfileContext;

