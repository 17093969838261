import { useState } from 'react';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { CreditCardInfo } from '../../types';
import { updateCreditCard } from '../../api';
import CreditCardForm from './CreditCardForm';
import { useAppContext } from '../../contexts/AppContext';

export default function PaymentEdit() {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');
  const [cardInfo, setCardInfo] = useState({} as CreditCardInfo);

  async function applyClicked() {
    try {
      setPageError('');
      const cardResult = await updateCreditCard(cardInfo);
      if (cardResult.status !== 204) {
        setPageError("We encountered an issue while updating your credit card information. Error: " + cardResult.status);
      } else {
        snackSuccess("Congrats! Your payment method has been successfully updated.");
        navigate('/account');
      }
      setLoading(true);
    }
    catch (error) {
      setPageError("A system error occurred while updating your credit card information. Please try again later, and if the issue persists, contact our support team for assistance. Error: " + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='form-page'>
      <h1>Manage Your Credit Card Details</h1>
      <p>Securely update or enter your credit card information. This card will be used for all your transactions and subscriptions on our platform. Rest assured, your payment information is encrypted and stored securely.</p>
      <CreditCardForm onCardChanged={setCardInfo} />
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Update Card'}</span>
      </LoadingButton>
    </div>
  );
};
