import Home from "./components/Home";
import Login from "./components/Login";
import VideoPlayer from "./components/VideoPlayer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Hunters from "./components/Hunters";
import MainLayout from "./components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { Profile } from "./types";
import ProfileContext from "./contexts/ProfileContext";
import { getProfile } from "./api";
import BountyPage from "./components/bounties/BountyPage";
import BountyCreate from "./components/bounties/BountyCreate";
import Hunter from "./components/Hunter";
import FormLayout from "./components/layouts/FormLayout";
import { Alert, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import HunterPost from "./components/HunterPost";
import Account from "./components/account/Account";
import HandleEdit from "./components/account/HandleEdit";
import EmailVerify from "./components/account/EmailVerify";
import SignUp from "./components/account/SignUp";
import Membership from "./components/account/Membership";
import SignUpPayment from "./components/account/SignUpPayment";
import BankEdit from "./components/account/BankEdit";
import IdentityVerify from "./components/account/IdentityVerify";
import SsnEdit from "./components/account/SsnEdit";
import NameEdit from "./components/account/NameEdit";
import AddressEdit from "./components/account/AddressEdit";
import PaymentEdit from "./components/account/PaymentEdit";
import PasswordEdit from "./components/account/PasswordEdit";
import EmailEdit from "./components/account/EmailEdit";
import PasswordForgot from "./components/account/PasswordForgot";
import PasswordReset from "./components/account/PasswordReset";
import ConfettiExplosion from "react-confetti-explosion";
import ProfilePicture from "./components/account/ProfilePicture";
import StageName from "./components/account/StageName";
import BioEdit from "./components/account/BioEdit";
import AppContext from "./contexts/AppContext";
import ClaimPage from "./components/bounties/ClaimPage";
import BountyPost from "./components/PostCreate";
import ClaimCreate from "./components/bounties/ClaimCreate";
import TestCode from "./components/TestCode";

function App() {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>("error");
  const [snackText, setSnackText] = useState("");
  const [snackKey, setSnackKey] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [profile, setProfile] = useState({ unresolved: true } as Profile);

  useEffect(() => {
    const updateProfile = (token: string | null) => {
      if (!token) {
        setProfile({} as Profile);
      } else {
        console.log("TOKEN: " + token);
        getProfile().then((result) => {
          setProfile(result.data);
        }).catch(() => {
          setProfile({} as Profile);
        });
      }
    };
  
    const token = localStorage.getItem("ss");
    updateProfile(token);
  
    const handleStorageChange = (event: any) => {
      if (event.key === "ss") {
        updateProfile(event.newValue);
      }
    };
  
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);  

  function snackClose() {
    setSnackOpen(false);
  }

  function snackError(text: string) {
    if (text) {
      setSnackText(text);
      setSnackSeverity("error");
      setSnackOpen(true);
      setSnackKey((prev) => prev + 1);
    }
  }

  function snackSuccess(text: string) {
    if (text) {
      setSnackText(text);
      setSnackSeverity("success");
      setSnackOpen(true);
      setSnackKey((prev) => prev + 1);
    }
  }

  function fireConfetti() {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  };

  return (
    <AppContext.Provider value={{ snackError, snackSuccess, snackClose, fireConfetti }}>
      <ProfileContext.Provider value={{ profile, setProfile }}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/test" element={<TestCode />} />
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path="/bounty/create" element={<BountyCreate />} />
                <Route path="/bounty/post/:id" element={<BountyPost />} />
                <Route path="/bounty/:id" element={<BountyPage />} />
                <Route path="/claim/:id" element={<ClaimPage />} />
                <Route path="/claim/create/:bountyId/:file" element={<ClaimCreate />} />
                <Route path="/profile/:id" element={<Hunter />} />
                <Route path="/hunters/" element={<Hunters />} />
                <Route path="/profile/" element={<Hunter />} />
              </Route> 
              <Route path="/" element={<FormLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot" element={<PasswordForgot />} />
                <Route path="/reset/:id" element={<PasswordReset />} />
                <Route path="/signup/:id" element={<SignUp />} />
                <Route path="/account" element={<Account />} />
                <Route path="/account/email" element={<EmailEdit />} />
                <Route path="/account/verify" element={<EmailVerify />} />
                <Route path="/account/password" element={<PasswordEdit />} />
                <Route path="/account/handle" element={<HandleEdit />} />
                <Route path="/account/stagename" element={<StageName />} />
                <Route path="/account/bio" element={<BioEdit />} />
                <Route path="/account/picture" element={<ProfilePicture />} />
                <Route path="/account/payment" element={<PaymentEdit />} />
                <Route path="/account/name" element={<NameEdit />} />
                <Route path="/account/address" element={<AddressEdit />} />
                <Route path="/account/ssn" element={<SsnEdit />} />
                <Route path="/account/identity" element={<IdentityVerify />} />
                <Route path="/account/bank" element={<BankEdit />} />
                <Route path="/member/verify" element={<EmailVerify next="/member/handle" />} />
                <Route path="/member/handle" element={<HandleEdit next="/member/membership" />} />
                <Route path="/member/membership" element={<Membership next="/member/payment" />} />
                <Route path="/member/payment" element={<SignUpPayment />} />
                <Route path="/creator/verify" element={<EmailVerify next="/creator/handle" />} />
                <Route path="/creator/handle" element={<HandleEdit next="/creator/stagename" />} />
                <Route path="/creator/stagename" element={<StageName next="/creator/bio" />} />
                <Route path="/creator/bio" element={<BioEdit next="/creator/picture" />} />
                <Route path="/creator/picture" element={<ProfilePicture next="/creator/name" />} />
                <Route path="/creator/name" element={<NameEdit next="/creator/address" />} />
                <Route path="/creator/address" element={<AddressEdit next="/creator/ssn" />} />
                <Route path="/creator/ssn" element={<SsnEdit next="/creator/identity" />} />
                <Route path="/creator/identity" element={<IdentityVerify next="/creator/bank" />} />
                <Route path="/creator/bank" element={<BankEdit next="/" />} />
                <Route path="/profile/post" element={<HunterPost />} />
              </Route>
            </Routes>
          </Router>
          <Snackbar
            key={snackKey}
            open={snackOpen}
            autoHideDuration={6000}
            onClose={snackClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={snackClose}
              severity={snackSeverity}
              sx={{ width: "100%", fontSize: 18 }}
            >
              {snackText}
            </Alert>
          </Snackbar>
          { showConfetti && <ConfettiExplosion /> }
        </ThemeProvider>
      </ProfileContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
