
import { useEffect, useState } from 'react';
import { appConfig } from '../appConfig';
import './ImageViewer.scss';

interface ImageViewerProps {
  fileName: string;
  width: number;
  height: number;
}

export default function ImageViewer({ fileName, width, height }: ImageViewerProps) {
  const [src, setSrc] = useState('blank');

  useEffect(()=> {
    setSrc('blank');
    if (fileName) {
      const final = new Image();
      final.src = appConfig.mediaUrl + 'f-' + fileName + '.webp';
      if (final.complete) {
        setSrc(final.src);
      } else {
        const thumb = new Image();
        thumb.src = appConfig.mediaUrl + 't-' + fileName + '.webp';
        if (thumb.complete) {
          setSrc(thumb.src);
          final.onload = () => {
            setSrc(final.src);
          }
        } else {
          thumb.onload = () => {
            setSrc(thumb.src);
            if (final.complete) {
              setSrc(final.src);
            } else {
              final.onload = () => {
                setSrc(final.src);
              }
            }
          }
        }
      }
    }
  }, [fileName]);

  return (
    <img className='image-viewer' src={src} alt='Bounty Claim' width={width} height={height}></img>
  );
}