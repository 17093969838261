import { PutObjectCommand, PutObjectCommandInput, S3Client } from "@aws-sdk/client-s3";
import { useState } from "react";

export default function TestCode() {
  const s3Client = new S3Client({
    forcePathStyle: false,
    endpoint: "https://sfo2.digitaloceanspaces.com",
    region: "us-east-1",
    credentials: {
      accessKeyId: "DO00C62KPPGWDEZFYFLX",
      secretAccessKey: "CcewTva2zJ2c9VBHdm263IgXsSjCGL/OswAGHd+DyrI"
    }
  });

  const uploadFile = async (file: File) => {
    const params: PutObjectCommandInput = {
      Bucket: "bounty",
      Key: file.name,
      Body: file,
      ACL: "public-read",
    };

    try {
      const data = await s3Client.send(new PutObjectCommand(params));
      console.log(
        "Successfully uploaded object: " +
          params.Bucket +
          "/" +
          params.Key
      );
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadFile(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileInputChange} />
    </div>
  );
}