import { useLocation } from "react-router-dom";
import BountyFeed from "./bounties/BountyFeed";

export default function Home() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get('q') ?? 'cats';
  
  return (
    <div className="home">
      <BountyFeed search={searchTerm} />
    </div>
  );
}
