import './HunterBar.scss';
import { Profile } from "../../types";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

interface HunterBarProps {
  profile: Profile;
}

export default function HunterBar({ profile }: HunterBarProps) {
  const navigate = useNavigate();

  function handleClick() {
    navigate('/profile/' + profile.handle);
  }

  if (!profile) return <div />
  return (
    <div className='hunter-bar' onClick={handleClick} style={{backgroundImage: 'url(https://videos.bounty.fan/' + (profile.banner ?? 'blank.jpeg')  + ')'}}>
      <div className='picture' style={{backgroundImage: 'url(https://videos.bounty.fan/' + (profile.picture ?? 'blank.jpeg') + ')'}} />
      <div className='stack'>
        <div className='handle'>{profile.handle}</div>
        <div className='info-row'>
          <span><FontAwesomeIcon icon={faUser} />3.4 K</span>
          <span><FontAwesomeIcon icon={faImages} />134</span>
        </div>
      </div>
    </div>
  );
}