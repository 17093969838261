import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getUser, sendReset } from '../../api';
import { isValidEmail } from '../../globals';

export default function PasswordForgot() {
  const [resolved, setResolved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [pageError, setPageError] = useState('');
  const [pageSuccess, setPageSuccess] = useState('');

  useEffect(() => {
    getUser().then((result) => {
      setResolved(true);
      if (result.status === 200) {
        setEmail(result.data.email);
      }
    });
  }, []);

  async function applyClicked() {
    try {
      setPageError('');
      setPageSuccess('');
      setLoading(true);
  
      if (!isValidEmail(email)) {
        setPageError("Please enter a valid email address. Ensure it's correctly formatted.");
      } else {
        const result = await sendReset(email);
        switch (result.status) {
          case 404:
            setPageError("Email address not found. If you're new, please sign up.");
            break;
          case 204:
            setPageSuccess("A password reset email has been sent. Please check your inbox.");
            break;
          default:
            setPageError("Unexpected error occurred (Error: " + result.status + "). Please verify your email address and try again, or contact our support team for assistance.");
        }
      }
    } catch (error) {
      setPageError("A system error occurred. We apologize for the inconvenience. Please try again later. If the issue continues, please contact our support team for help. Error details: " + error);
    } finally {
      setLoading(false);
    }
  }

  if (!resolved) {
    return <div className='form-page' />;
  }
  
  return (
  <div className='form-page'>
    <h1>Password Recovery</h1>
    <h2>Request a Password Reset Link</h2>
    <p>
      Forgetting passwords happens to the best of us. To reset your password, simply enter your email address below. We'll send you a link with instructions to securely create a new password.
    </p>
    <TextField
      label="Email Address"
      variant="outlined"
      value={email}
      helperText="Enter the email address associated with your account."
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          applyClicked();
        }
      }}
      onChange={event => {
        setEmail(event.target.value.substring(0, 320));
      }}
    />
    {pageError && <Alert severity="error">{pageError}</Alert> }
    {pageSuccess && <Alert severity="success">{pageSuccess}</Alert> }
    <LoadingButton 
      variant="contained" 
      loading={loading} 
      onClick={applyClicked}>
      <span>{"Send Reset Link"}</span>
    </LoadingButton>
  </div>
  );
};
